import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyButton from 'components/MyButton/MyButton';
import { DealDetail } from 'features/deals/models/DealDetail';
import QuoteCreateModal from 'features/quotes/components/QuoteCreateModal/QuoteCreateModal';
import QuoteStatusBadge from 'features/quotes/components/QuoteStatusBadge/QuoteStatusBadge';
import QuoteStatus from 'features/quotes/enums/QuoteStatus';
import { Quote } from 'features/quotes/models/Quote';
import quotesApi from 'features/quotes/quotes.api';
import Icons from 'Icons';
import React from 'react';
import { formatCurrency } from 'utils/helpers';
import './DealQuotesTab.scss';

const COLUMNS = ColumnBuilder<Quote>()
    .column({
        key: 'tuid',
        label: 'Quote',
        isSortable: true,
        getValue: item => item.tuid,
    })
    .column({
        key: 'status',
        label: 'Status',
        isSortable: false,
        getValue: () => QuoteStatus.Quote,
        renderValue: (val, item) => <StatusCell model={item} />,
    })
    .column({
        key: 'products',
        label: 'Products',
        isSortable: false,
        getValue: item => item.context.orderLineCount || null,
        emptyDash: true,
        align: 'center',
    })
    .column({
        key: 'quantity',
        label: 'Total qty',
        isSortable: false,
        getValue: item => item.context.totalQuantity || null,
        emptyDash: true,
        align: 'center',
    })
    .column({
        key: 'total',
        label: 'Total price',
        isSortable: true,
        getValue: item => {
            if (item.context.orderLineCount === 0) {
                return null;
            }
            return item.totalSellPriceOverride ?? item.totalSellPrice;
        },
        renderValue: (value, item) => {
            if (item.totalSellPriceOverride === null) {
                return formatCurrency(value);
            }
            return (
                <>
                    <s>{formatCurrency(item.totalSellPrice)}</s>{' '}
                    {formatCurrency(item.totalSellPriceOverride)}
                </>
            );
        },
        emptyDash: true,
    })
    .build();

export function DealQuotesTab({ model }: { model: DealDetail }) {
    const query = quotesApi.useQuoteListQuery(model.id);

    const [showAddQuoteModal, setShowAddQuoteModal] = React.useState(false);

    return (
        <>
            <div className="DealQuotesTab__Topbar">
                <MyButton
                    label="Add quote"
                    IconLeft={Icons.Plus}
                    buttonType="Accent"
                    onClick={() => setShowAddQuoteModal(true)}
                />
            </div>

            <DataTable
                columns={COLUMNS}
                isLoading={query.isLoading}
                isError={query.isError}
                data={query.data}
                rowLinkTo={quote => `quotes/${quote.id}`}
            />

            {showAddQuoteModal && (
                <QuoteCreateModal
                    dealId={model.id}
                    close={() => setShowAddQuoteModal(false)}
                />
            )}
        </>
    );
}

function StatusCell({ model }: { model: Quote }) {
    return (
        <div className="DealQuotesTab__StatusCell">
            <QuoteStatusBadge
                status={model.status}
                size="small"
            />
            {model.isArchived && (
                <div className="DealQuotesTab__StatusCell__Archived">Archived</div>
            )}
        </div>
    );
}
