import Icons from 'Icons';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import { applySearchFilter } from 'components/DataTableFilters/Filters/SearchFilter';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { useWindowGroupOptions } from 'features/orders/helpers/orderProductHelper';
import { validateProducts } from 'features/orders/helpers/orderValidationHelper';
import { OrderWindow } from 'features/orders/models/OrderWindow';
import { OrderWindowProduct } from 'features/orders/models/OrderWindowProduct';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useMemo, useState } from 'react';
import { formatCurrency } from 'utils/helpers';
import OrderProductAddModal from '../../../orders/components/OrderProductAddModal/OrderProductAddModal';
import QuoteEditModal from '../QuoteEditModal/QuoteEditModal';
import QuotePriceEditModal from '../QuotePriceEditModal/QuotePriceEditModal';
import { QuoteProductRow } from '../QuoteProductRow/QuoteProductRow';
import QuotePdfModal from '../QuoteReviewModal/QuotePdfModal';
import QuoteStatusBadge from '../QuoteStatusBadge/QuoteStatusBadge';
import './QuoteDetailTab.scss';

// Quote (quotes)
export default function QuoteDetailTab({
    model,
    windows,
}: {
    model: QuoteDetail;
    windows: OrderWindow[];
}) {
    const breakpoints = useBreakpoints();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showPriceEditModal, setShowPriceEditModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);

    return (
        <div className="QuoteDetailTab">
            <div className="QuoteDetailTab__DetailsPanel">
                <PropertyContainer layout="table">
                    <PropertyDisplay
                        label="Description"
                        verticalAlign="top"
                        value={model.description}
                    />

                    <PropertyDisplay
                        label="Status"
                        verticalAlign="top"
                        value={
                            <div className="QuoteDetailTab__StatusBadges">
                                {model?.isArchived && <ArchivedBadge />}
                                <QuoteStatusBadge status={model.status} />
                            </div>
                        }
                    />
                </PropertyContainer>

                {!model.isReadOnly && (
                    <MyButton
                        className="QuoteDetailTab__DetailsPanel__EditButton"
                        label="Edit"
                        IconLeft={Icons.Edit}
                        buttonType="Hollow"
                        onClick={() => setShowEditModal(true)}
                    />
                )}
            </div>

            <ProductList
                order={model}
                windows={windows}
                isReadOnly={model.isReadOnly}
            />

            {model?.context.totalQuantity > 0 && (
                <div className="QuoteDetailTab__Footer">
                    <div className="QuoteDetailTab__Footer__Content">
                        <div className="QuoteDetailTab__Footer__Content__TotalQuantity">
                            {model?.context.totalQuantity}{' '}
                            {model?.context.totalQuantity === 1 ? 'product' : 'products'}, total
                        </div>
                        {model?.totalSellPriceOverride !== null && (
                            <div className="QuoteDetailTab__Footer__Content__OriginalPrice">
                                {formatCurrency(model?.totalSellPrice)}
                            </div>
                        )}

                        <div className="QuoteDetailTab__Footer__Content__Price">
                            {formatCurrency(model?.totalSellPriceOverride ?? model?.totalSellPrice)}

                            {/* Edit price button for quotes only */}
                            {!model.isReadOnly && (
                                <MyButton
                                    className="QuoteDetailTab__Footer__Content__Price__EditButton"
                                    IconLeft={Icons.Edit}
                                    title="Edit price"
                                    buttonType="None"
                                    size="small"
                                    onClick={() => setShowPriceEditModal(true)}
                                />
                            )}
                        </div>
                    </div>
                    <MyButton
                        label="View PDF"
                        buttonType="Hollow"
                        LinkComponent={MyButtonLinkNewTab}
                        onClick={() => setShowPdfModal(true)}
                        IconLeft={Icons.File}
                        fullWidth={breakpoints.isVerySmallOnly}
                    />
                </div>
            )}

            {showEditModal && (
                <QuoteEditModal
                    order={model}
                    close={() => setShowEditModal(false)}
                />
            )}
            {showPriceEditModal && (
                <QuotePriceEditModal
                    order={model}
                    close={() => setShowPriceEditModal(false)}
                />
            )}
            {showPdfModal && (
                <QuotePdfModal
                    model={model}
                    windows={windows}
                    close={() => setShowPdfModal(false)}
                />
            )}
        </div>
    );
}

function ArchivedBadge() {
    return <div className="QuoteDetailTab__ArchivedBadge">Archived</div>;
}

function ProductList({
    order,
    windows,
    isReadOnly,
}: {
    order: QuoteDetail;
    windows: OrderWindow[];
    isReadOnly?: boolean;
}) {
    const [filteredProducts, setFilteredProducts] = useState<OrderWindowProduct[]>();
    const [showAddProductModal, setShowAddProductModal] = useState(false);

    const breakpoints = useBreakpoints();

    const allProducts = useMemo(
        () => windows?.flatMap(w => w.window_products).filter(p => !!p.is_selected),
        [windows],
    );

    const labelOptions = useWindowGroupOptions(order.externalId);
    const filters = useMemo(
        () =>
            FilterBuilder<OrderWindowProduct>()
                .filter({
                    label: 'Search',
                    type: 'search',
                    getFields: item => [
                        item.master_products_name,
                        item.products_name,
                        item.details,
                    ],
                    applyFilter: (filter, value, item: OrderWindowProduct) => {
                        return applySearchFilter(
                            {
                                ...filter,
                                type: 'search',
                                getFields: i => [
                                    i.master_products_name
                                        ? i.master_products_name
                                        : i.products_name,
                                    i.details,
                                ],
                            },
                            value,
                            item,
                        );
                    },
                })
                .filter({
                    label: 'Filter',
                    type: 'select',
                    getField: () => '',
                    options: [
                        {
                            label: 'All products',
                            value: 'all',
                        },
                        {
                            label: 'With errors',
                            value: 'problems',
                        },
                    ],
                    defaultValue: 'all',
                    allowBlank: false,
                    applyFilter: (filter, value, item) => {
                        if (value === 'problems') {
                            const errors = validateProducts([item]);
                            return errors.length > 0;
                        }
                        return true;
                    },
                })
                .filter({
                    label: 'Window or group',
                    type: 'select',
                    getField: item => item.details,
                    options: [
                        { label: '(None)', value: '__NO_LABEL', isPlaceholder: true },
                        ...labelOptions,
                    ],
                    defaultValue: '',
                    allowBlank: true,
                    applyFilter: (filter, value, item: OrderWindowProduct) => {
                        if (value === '__NO_LABEL') {
                            return !item.details;
                        }
                        return item.details?.startsWith(value) ?? false;
                    },
                })
                .build(),
        [labelOptions],
    );
    const currentProductIds = useMemo(() => allProducts?.map(p => p.id) ?? [], [allProducts]);

    return (
        <div className="QuoteDetailTab__ProductList">
            {allProducts?.length === 0 ? (
                <div className="QuoteDetailTab__ProductList__Empty">
                    {isReadOnly ? (
                        <label>No products added</label>
                    ) : (
                        <>
                            <label>No products added yet</label>
                            <MyButton
                                label="Add product"
                                IconLeft={Icons.Plus}
                                buttonType="Accent"
                                onClick={() => setShowAddProductModal(true)}
                            />
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div className="QuoteDetailTab__ProductList__Header">
                        <h2>Products</h2>
                        <div className="QuoteDetailTab__ProductList__Header__Actions">
                            {!isReadOnly && (
                                <MyButton
                                    className="QuoteDetailTab__ProductList__Toolbar__AddButton"
                                    label={'Add'}
                                    IconLeft={Icons.Plus}
                                    buttonType="Accent"
                                    onClick={() => setShowAddProductModal(true)}
                                    fullWidth={breakpoints.isVerySmallOnly}
                                />
                            )}
                        </div>
                    </div>
                    <div className="QuoteDetailTab__ProductList__Toolbar">
                        <DataTableFilters
                            filters={filters}
                            data={allProducts}
                            onChange={setFilteredProducts}
                        />
                    </div>
                    {(filteredProducts?.length ?? 0) === 0 ? (
                        <div className="QuoteDetailTab__ProductList__ProductsFilteredOut">
                            No products in this order matched your search.
                        </div>
                    ) : (
                        <div className="QuoteDetailTab__ProductList__Products">
                            {filteredProducts?.map(p => (
                                <QuoteProductRow
                                    key={p.id}
                                    product={p}
                                />
                            ))}
                        </div>
                    )}
                </>
            )}

            {showAddProductModal && (
                <OrderProductAddModal
                    orderId={order.externalId}
                    currentProductIds={currentProductIds}
                    close={() => setShowAddProductModal(false)}
                />
            )}
        </div>
    );
}
