import { DocumentUploadResultDocument } from 'features/documents/models/DocumentUploadResult';
import { DocumentsResult, DocumentsResultSchema } from 'features/documents/models/DocumentsResult';
import { ApiTagType, api } from 'services/api';
import typescriptNaturalSort from 'typescript-natural-sort';
import { wait } from 'utils/helpers';
import { z } from 'zod';
import { OrderWindow, OrderWindowSchema } from './models/OrderWindow';
import { OrderWindowProduct } from './models/OrderWindowProduct';
import { OrderWindowProductNew } from './models/OrderWindowProductNew';
import { Product, ProductSchema } from './models/Product';
import { ProductDetail, ProductDetailSchema } from './models/ProductDetail';
import { ProductOption, ProductOptionSchema } from './models/ProductOption';

export const SALES_REP_NOT_SET = '__none';

export const ordersApi = api.injectEndpoints({
    endpoints: build => ({
        orderWindowList: build.query<OrderWindow[], number>({
            query: id => ({
                url: `/old/quotes/${id}/windows`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(OrderWindowSchema),
                });
                return schema.parse(result).data;
            },
            providesTags: (res, err, id) => [
                { type: ApiTagType.Quote, id },
                { type: ApiTagType.PurchaseOrder, id },
            ],
        }),

        orderWindowClone: build.mutation<
            number, // returns new product id
            {
                orderId: number;
                windowId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/windows/copy-and-paste`,
                method: 'POST',
                data: {
                    order_window_id: args.windowId,
                    type: 'windows-and-products',
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    windows: z.array(z.number()),
                    products: z.array(z.number()),
                });
                return schema.parse(result).products[0];
            },
            invalidatesTags: [ApiTagType.Quote, ApiTagType.PurchaseOrder],
        }),

        productList: build.query<Product[], { manufacturerId?: number }>({
            query: ({ manufacturerId }) => ({
                url: `/old/products/select/windows`,
                method: 'GET',
                params: {
                    limit: 9999,
                    offset: 0,
                    manufacturer_id: manufacturerId,
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(ProductSchema),
                });
                return schema.parse(result).data;
            },
        }),

        productDetail: build.query<ProductDetail, number>({
            query: id => ({
                url: `/old/products/${id}/detail`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: ProductDetailSchema,
                });
                return schema.parse(result).data;
            },
        }),

        orderProductAdd: build.mutation<number, OrderWindowProductNew>({
            async queryFn(model, _queryApi, _extraOptions, fetchWithBQ) {
                // Create a window first
                const windowResult = await fetchWithBQ({
                    url: `/old/quotes/${model.order_id}/windows`,
                    method: 'POST',
                    data: {
                        order_windows: [
                            {
                                order_id: model.order_id,
                                quantity: 1,
                                width: model.width,
                                height: model.height,
                            },
                        ],
                    },
                });

                if (windowResult.error) {
                    return {
                        error: windowResult.error,
                    };
                }

                const windowResultParsed = z
                    .object({
                        data: z.array(
                            z.object({
                                id: z.number(),
                            }),
                        ),
                    })
                    .parse(windowResult.data);

                const windowId = windowResultParsed.data[0].id;

                // Add product to above window
                const productResult = await fetchWithBQ({
                    url: `/old/quotes/${model.order_id}/windows/${windowId}/products`,
                    method: 'POST',
                    data: {
                        order_id: model.order_id,
                        order_window_id: windowId,
                        product_id: model.product_id,
                        brand_id: model.brand_id,
                        category_id: model.category_id,
                        details: model.details,
                        notes: model.notes,
                        quantity: model.quantity,
                        width: model.width,
                        height: model.height,
                    },
                });

                if (productResult.error) {
                    return {
                        error: productResult.error,
                    };
                }

                const productResultParsed = z
                    .object({
                        order_window_product_id: z.number(),
                    })
                    .parse(productResult.data);

                const productId = productResultParsed.order_window_product_id;

                return {
                    data: productId,
                };
            },

            invalidatesTags: [ApiTagType.Quote, ApiTagType.PurchaseOrder],
        }),

        orderProductUpdate: build.mutation<void, OrderWindowProduct>({
            query: model => ({
                url: `/old/quotes/${model.order_id}/windows/${model.order_window_id}/products/${model.id}`,
                method: 'PUT',
                data: {
                    site_mount: '',
                    details: model.details,
                    notes: model.notes,
                    width: model.width,
                    height: model.height,
                    quantity: model.quantity,
                    type: model.products_type,
                    is_taxable: model.is_taxable,
                    has_freight: model.has_freight,
                    has_installation: model.has_installation,
                },
            }),
            invalidatesTags: [ApiTagType.Quote, ApiTagType.PurchaseOrder],
        }),

        orderProductOptions: build.query<
            ProductOption[],
            {
                orderId: number;
                windowId: number;
                productId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/windows/${args.windowId}/products/${args.productId}/options/display`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(ProductOptionSchema),
                });
                return schema.parse(result).data;
            },
            providesTags: (res, err, args) => [
                { type: ApiTagType.Quote, id: args.orderId },
                { type: ApiTagType.PurchaseOrder, id: args.orderId },
            ],
        }),

        orderWindowDelete: build.mutation<
            void,
            {
                orderId: number;
                windowId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/windows/${args.windowId}`,
                method: 'POST',
                data: {
                    _method: 'DELETE',
                },
            }),
            async onQueryStarted(rgs, { dispatch, queryFulfilled }) {
                // Wait until query is finished to invalidate tags
                // This gives the UI time to close components that might attempt to fetch irrelevant data, eg product options
                await queryFulfilled;
                await wait(1);
                dispatch(
                    ordersApi.util.invalidateTags([ApiTagType.Quote, ApiTagType.PurchaseOrder]),
                );
            },
        }),

        /** Get documents attached to an order */
        orderDocuments: build.query<DocumentsResult, number>({
            query: orderId => ({
                url: `/old/quotes/${orderId}/documents`,
                method: 'GET',
            }),
            transformResponse: result => DocumentsResultSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.OrderDocument, id }],
        }),

        /** Attach an uploaded document to a quote */
        orderDocumentAttach: build.mutation<
            void,
            {
                document: DocumentUploadResultDocument;
                orderId: number;
                categoryId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/documents`,
                method: 'POST',
                data: {
                    data: [
                        {
                            ...args.document,
                            category: args.categoryId,
                        },
                    ],
                    directUpload: true,
                    is_image_upload: true, // always true?
                    model: '',
                    model_id: '',
                    type: null,
                },
            }),
            invalidatesTags: (req, res, args) => [
                { type: ApiTagType.OrderDocument, id: args.orderId },
            ],
        }),

        /** Archive document */
        orderDocumentArchive: build.mutation<
            void,
            {
                orderId: number;
                documentId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/documents/${args.documentId}`,
                method: 'POST',
                data: {
                    model: '',
                    model_id: '',
                    _method: 'DELETE',
                },
            }),
            invalidatesTags: (req, res, args) => [
                { type: ApiTagType.OrderDocument, id: args.orderId },
            ],
        }),

        /** Recalculate all windows in quote (apply updates) */
        orderWindowsRecalculate: build.mutation<
            void,
            {
                orderId: number;
                windowIds: number[];
                isFirstCalculation?: boolean;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/windows/recalculate`,
                method: 'POST',
                data: {
                    order_id: args.orderId,
                    first_run: true,
                    last_run: true,
                    ...(args.isFirstCalculation
                        ? {
                              type: 'unanswered-mandatory-fields',
                              order_window_ids: args.windowIds,
                          }
                        : {
                              ids: args.windowIds,
                          }),
                },
            }),
            invalidatesTags: [ApiTagType.Quote, ApiTagType.PurchaseOrder],
        }),

        salesRepNames: build.query<string[], void>({
            query: () => ({
                url: `/quotes/sales-reps`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.array(z.string());
                const names = schema.parse(result);
                names.sort(typescriptNaturalSort);
                return names;
            },
            providesTags: [ApiTagType.PurchaseOrder, ApiTagType.Quote],
        }),

        orderFreightEstimate: build.query<
            number,
            { orderId: number; manufacturerId: number; shippingMethodId: number }
        >({
            query: args => ({
                url: `/order/${args.orderId}/manufacturer/${args.manufacturerId}/freight`,
                method: 'POST',
                data: {
                    shippingMethodId: args.shippingMethodId,
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    estimate: z.number(),
                });
                return schema.parse(result).estimate;
            },
        }),
    }),
});

export default ordersApi;
