import { faker } from '@faker-js/faker';
import { generateTuid } from 'utils/helpers';
import QuoteStatus from '../enums/QuoteStatus';
import { QuoteDetail } from '../models/QuoteDetail';

export const mockedQuotes: QuoteDetail[] = [];

export function generateFakeQuoteDetail(id?: string, dealId?: string): QuoteDetail {
    const quote: QuoteDetail = {
        id: id ?? faker.string.uuid(),
        externalId: faker.number.int(),
        createdAt: faker.date.recent().toISOString(),
        tuid: generateTuid('Q'),
        description: faker.lorem.words(10),
        status: QuoteStatus.Quote,
        totalSellPrice: faker.number.float({ min: 100, max: 10000, precision: 2 }),
        totalSellPriceOverride:
            faker.helpers.maybe(() => faker.number.float({ min: 100, max: 10000, precision: 2 }), {
                probability: 0.2,
            }) ?? null,
        isArchived: faker.datatype.boolean(),
        isReadOnly: faker.datatype.boolean(),
        context: {
            totalQuantity: faker.number.int(),
            orderLineCount: faker.number.int(),
            deal: {
                id: dealId ?? faker.string.uuid(),
                tuid: generateTuid('D'),
                isArchived: faker.datatype.boolean(),
                workflowId: faker.string.uuid(),
                customerName: faker.company.name(),
                description: faker.lorem.words(10),
                statusId: faker.string.uuid(),
                sortOrder: faker.number.bigInt().toString(),
            },
            workflow: {
                id: faker.string.uuid(),
                name: faker.lorem.words(2),
            },
            pendingUpdates: null,
        },
        isQuote: true as const,
        isPurchase: false as const,
    };
    mockedQuotes.push(quote);
    return quote;
}
