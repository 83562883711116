import QuoteStatus, { QuoteStatusDisplay } from 'features/quotes/enums/QuoteStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './QuoteStatusBadge.scss';

export default function QuoteStatusBadge({
    status,
    size = 'large',
    className,
}: {
    status?: QuoteStatus;
    size?: 'small' | 'large';
    className?: string;
}) {
    return status ? (
        <div
            className={coalesceClassNames(
                'QuoteStatusBadge',
                `QuoteStatusBadge--${size}`,
                status,
                className,
            )}
        >
            {QuoteStatusDisplay.display(status)}
        </div>
    ) : null;
}
