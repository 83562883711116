import { PendingUpdatesSchema } from 'models/PendingUpdates';
import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import QuoteStatus from '../enums/QuoteStatus';

export const QuoteDetailSchema = z
    .object({
        id: z.string(),
        externalId: z.string().transform(value => parseInt(value, 10)),
        tuid: z.string(),
        createdAt: dateTimeInstant(),
        description: z.string(),
        status: z.nativeEnum(QuoteStatus),
        isArchived: z.boolean(),
        isReadOnly: z.boolean().default(false),
        totalSellPrice: z.number(),
        totalSellPriceOverride: z.number().nullable(),
        context: z.object({
            totalQuantity: z.number(),
            orderLineCount: z.number(),
            deal: z.object({
                id: z.string(),
                tuid: z.string(),
                workflowId: z.string(),
                customerName: z.string(),
                description: z.string(),
                statusId: z.string(),
                sortOrder: z.string(),
                isArchived: z.boolean().optional(),
            }),
            workflow: z.object({
                id: z.string(),
                name: z.string(),
            }),
            pendingUpdates: PendingUpdatesSchema.nullable(),
        }),
    })
    .transform(data => ({
        ...data,
        isQuote: true as const,
        isPurchase: false as const,
    }));

export type QuoteDetail = z.infer<typeof QuoteDetailSchema>;
